import { useEffect } from 'react';

export const useFocusElement = (
    focus: boolean,
    ref: React.RefObject<HTMLElement | null>
) => {
    useEffect(() => {
        if (focus) {
            ref.current?.focus();
        }
    }, [ref, focus]);
};
