import React from 'react';
import { Text } from 'bb/i18n';
import { Row } from 'bb/ui/Flex';
import css from './badge.module.scss';

type NumberBadgeProps = {
    number: number;
};

export const NumberBadge = ({ number }: NumberBadgeProps) => (
    <Row justifyContent="center" alignItems="center" className={css.number}>
        <Text color="primary-black" as="span" variant="badge">
            {number}
        </Text>
    </Row>
);
